import React, { useState } from "react"
import { useAllKontentResourceStringData } from "../../graphql-static/use-resource-strings"
import { getResourceStringByid } from "../../utils/resource-string-helper"
import { AuthenticatedResourcesStrings } from "../../types/enums/authenticated-resource-strings"
import { createNewEmptyContractByCustomerId, getUnconfirmedContractsCountByCustomerId } from "../../middleware/middleware-layer"
import { navigate } from "gatsby-link"
import { AccountInfo } from "@azure/msal-common"
import { IPublicClientApplication } from "@azure/msal-browser"
import { ConfirmationModal } from "../confirmation-modal"
import { Modal } from "../modal"
import { Button } from "../button"

export type newContractButtonProps = {
    accounts: AccountInfo[]
    customer: any
    className?: string
    instance: IPublicClientApplication
}

export const NewContractButton = (props: newContractButtonProps) => {
    const resourceStrings = useAllKontentResourceStringData()

    const newContractButtonText = getResourceStringByid(
      resourceStrings,
      AuthenticatedResourcesStrings.INTERNAL_AUTHENTICATED_CXDASHBOARD_NEW_CONTRACT_NEW_BUTTON
    )

    const newContractButtonCreatingText = getResourceStringByid(
        resourceStrings,
        AuthenticatedResourcesStrings.INTERNAL_AUTHENTICATED_CXDASHBOARD_NEW_CONTRACT_NEW_BUTTON_CREATING
      )

    const [checkingUnconfirmedContracts, setCheckingUnconfirmedContracts] = useState<boolean>(false)
    const [isUnconfirmedModalOpen, setIsUnconfirmedModalOpen] = useState<boolean>(false)
    const [creatingContract, setCreatingContract] = useState<boolean>(false)
    const [unconfirmedContractsCount, setUnconfirmedContractsCount] = useState(0)
    const [createNewEmptyContractErrorMessage, setCreateNewEmptyContractErrorMessage] = useState<string>()
    const [isCreatingContractFailedModalOpen, setIsCreatingContractFailedModalOpen] = useState<boolean>(false)

    const handleCreateNewContract = () => {
      // First check if there are already any unconfirmed contracts
      setCheckingUnconfirmedContracts(true)
      getUnconfirmedContractsCountByCustomerId(props.accounts, props.instance, props.customer.customerId)
        .then((results: Record<string, any>) => {
            if (results.errors?.length > 0) {
              setCheckingUnconfirmedContracts(false)
            } else {
              const count = results?.data?.internalGetUnconfirmedContractsCountByCustomerId || 0
              if (count > 0) {
                setUnconfirmedContractsCount(count)
                setIsUnconfirmedModalOpen(true)
              } else {
                setCheckingUnconfirmedContracts(false)
                createNewEmptyContract()
              }
            }
        })
        .catch(err => {
            console.log(err)
            setCheckingUnconfirmedContracts(false)
        })
    }

    const createNewEmptyContract = () => {
      setIsUnconfirmedModalOpen(false)
      setCreatingContract(true)
      createNewEmptyContractByCustomerId(props.accounts, props.instance, props.customer.customerId)
        .then((results: Record<string, any>) => {
            if (results.errors?.length > 0) {
              setCreateNewEmptyContractErrorMessage(results.errors[0].message)
              setIsCreatingContractFailedModalOpen(true)
              setCreatingContract(false)
            }
            const contractId = results?.data?.internalCreateNewEmptyContract?.contractId
            if (contractId) {
              navigate(`/cx-dashboard/customer/${props.customer.customerId}/contracts/saved/${contractId}`)
            }
        })
        .catch(err => {
          console.log(err)
          setCreatingContract(false)
        })
    }

    const viewUnconfirmedContracts = () => {
      setCheckingUnconfirmedContracts(false)
      setIsUnconfirmedModalOpen(false)
      navigate(`/cx-dashboard/customer/${props.customer.customerId}/contracts/all-contracts?status=Unconfirmed`)
    }

    return (
      <>
        <button
          id="btn-new-contract"
          className={`${
            creatingContract || checkingUnconfirmedContracts ? "cursor-not-allowed " : ""
          }${props.className ? `${props.className} ` : ""}self-start px-4 py-2 mr-3 text-sm uppercase font-medium border border-transparent rounded-full shadow-sm group text-white bg-horizonred hover:bg-tertiaryHover hover:text-horizonhover`}
          onClick={handleCreateNewContract}
          disabled={creatingContract}
        >
          {creatingContract ? (
            <span className="animate-pulse">{newContractButtonCreatingText}</span>
          ) : checkingUnconfirmedContracts ? (
            <span className="animate-pulse">Checking...</span>
          ) : (
            newContractButtonText
          )}
        </button>
        <ConfirmationModal
          cancelBtnText="No"
          closeModal={createNewEmptyContract}
          confirmationBtnText="Yes"
          descriptionTexts={[
            `There are ${unconfirmedContractsCount} unconfirmed contracts already on the system for this customer. Do you want to see these first?`
          ]}
          handleConfirm={viewUnconfirmedContracts}
          isOpen={isUnconfirmedModalOpen}
        />
        <Modal
          title="Create New Contract Failed"
          isOpen={isCreatingContractFailedModalOpen} 
          closeModal={() => {}}
        >
          <div>
            <div className="mt-4 mb-2">
              <p className="leading-5">The new contract could not be created.</p>
            </div>
            <div className="mb-8">
              <p className="leading-5">{createNewEmptyContractErrorMessage}</p>
            </div>
            <div>
              <Button outline onClick={() => {setIsCreatingContractFailedModalOpen(false)}}>
                OK
              </Button>
            </div>
          </div>
        </Modal>
      </>
    )
}